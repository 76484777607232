import { GetServerSidePropsContext } from "next";

import { useUI } from "@/hooks/useUIStore";
import { FactSet } from "@/models/facts/facts.types";
import { loadEntity, loadWorkspaceData } from "@/server/loaders";
import { isRedirect, validateEntityPath } from "@/server/slugs";
import { Attribute, Entity, GenericProfile, PipelineRunStatus, ServerProps } from "@/types";
import { useEffect, useState } from "react";

import EntityLayoutV3 from "@/components/entities/EntityLayout";

import NotFound from "@/components/entities/NotFound";
import errorTracker from "@/lib/errorTracker";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

type Props = {
  entity: Entity | undefined;
  aliases?: string[];
  todayEntityVisits?: string[];
  attributes: Attribute[];
  status: PipelineRunStatus;
  snapshot: GenericProfile;
  lastUpdated?: string;
} & ServerProps;

export default function EntityPage(props: Props) {
  const { entity, attributes, status, snapshot, aliases, ...rest } = props;
  const [missingSnapshotErrorSent, setMissingSnapshotErrorSent] = useState(false);
  const posthog = usePostHog();
  useUI(rest);
  useEffect(() => {
    if (entity) {
      void entityStore.load(entity, snapshot, { attributes, aliases });
      posthog?.capture("view-entity", {
        entityId: entity.id,
        type: entity.type,
        name: entity.name,
      });
    }
  }, [entity, attributes, snapshot, aliases]);

  useEffect(() => {
    if (status == PipelineRunStatus.IN_PROGRESS) {
      posthog?.capture("entity-in-progress", {
        entityId: entity?.id,
        type: entity?.type,
      });
    } else if (status != PipelineRunStatus.COMPLETED) {
      posthog?.capture("entity-status-" + status, {
        entityId: entity?.id,
        type: entity?.type,
      });
    }
  }, [entity, status]);

  const refreshParam = useSearchParams().get("refresh");

  useEffect(() => {
    uiStore.ingestUtmSource("entity-visit", { entityId: entity?.id });
    uiStore.getAndDeleteUrlParam("refresh");
  }, [entity?.id, refreshParam]);

  if (!entity) return <NotFound />;

  // this check makes sure only when the snapshot object exist do we load the layout
  if (snapshot && Object.keys(snapshot).length > 0) {
    return <EntityLayoutV3 {...props} />;
  } else {
    if (!missingSnapshotErrorSent) {
      errorTracker.sendError(new Error("Entity snapshot is empty, falling back to v2 layout"), {
        entityId: entity?.id,
        entityType: entity?.type,
        entitySlug: entity?.slug || "",
      });
      setMissingSnapshotErrorSent(true);
    }
    return null;
  }
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { session, redirect, ...rest } = await loadWorkspaceData(context);

  const pathname = context.resolvedUrl;
  const queryParams = context.query;
  const slugResult = await validateEntityPath(decodeURI(pathname), context.query.id as string);

  if (!slugResult) {
    return {
      notFound: true,
    };
  }

  if (isRedirect(slugResult)) {
    const urlSearchParams = new URLSearchParams(queryParams as Record<string, string>);
    urlSearchParams.delete("id");
    return {
      redirect: {
        ...slugResult.redirect,
        destination:
          slugResult.redirect.destination + (queryParams ? "?" + urlSearchParams.toString() : ""),
      },
    };
  }

  const skip = context.query.skip;
  const entityProps = await loadEntity(slugResult, session?.dbUser, {
    skipBuild: skip == "true",
    entityVisitCount: rest.entityVisitCount,
  });
  return clearUndefined({
    props: {
      ...rest,
      ...entityProps,
    } as Props,
  });
};

/**
 * Next.js doesn't allow undefined values in props. This function removes them.
 * @param obj
 * @returns
 */
function clearUndefined<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}
