import AuthoredMediaRow from "@/components/media/AuthoredMediaRow";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { ClearButton } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import { useDevTools } from "@/hooks/useUIStore";
import { entityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

export default withErrorBoundary(function AuthoredMediaV2() {
  const media = useStore(entityStore.authoredMedia);
  const crawlResults = useStore(entityStore.crawlResults);
  const canEdit = useStore(entityStore.canEdit);

  const addMedia = () => {
    entityStore.addAuthoredMediaModal();
  };

  if (!media.length) return null;

  return (
    <ProfileBubble
      title="Authored Media"
      section={ProfilePageSection.AuthoredMedia}
      className="flex flex-col"
    >
      {media.length == 0 && <div className="text-gray-500">No authored media</div>}
      {canEdit && (
        <ClearButton
          className="absolute top-2 right-2 text-gray-300 hover:text-gray-500"
          onClick={addMedia}
        >
          <DocumentPlusIcon className="w-6 h-6" />
        </ClearButton>
      )}
      <ExpandoList
        items={media}
        limit={3}
        seeMoreClassName="pt-4 text-center"
        className="list-inside text-gray-700 gap-4"
        divideExpander
        renderItem={(m, idx) => (
          <AuthoredMediaRow media={m} idx={idx} crawlResult={crawlResults[m.url || ""]} />
        )}
      />
    </ProfileBubble>
  );
});
