import { smartTruncate } from "@/lib/stringUtils";
import { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

interface ExpandoProps {
  text: string;
  limit: number;
  seeMoreClassName?: string;
}

function ExpandoText({ text, limit, seeMoreClassName }: ExpandoProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const showExpando = text.length > limit + 20;
  const visibleText = !showExpando || isExpanded ? text : smartTruncate(text, limit);

  return (
    <>
      {visibleText}
      {showExpando && (
        <>
          <button
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            className={twMerge(
              "text-blue-600 hover:text-blue-800 transition duration-150 ease-in-out cursor-pointer ml-2",
              seeMoreClassName,
            )}
          >
            {isExpanded ? "see less" : `see more`}
          </button>
        </>
      )}
    </>
  );
}

export default ExpandoText;
