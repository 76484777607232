/* eslint-disable @next/next/no-img-element */
import Button, { ButtonVariant } from "@/components/ui/Button";
import { favicon, prettyError } from "@/lib/miscUtils";
import { prettyUrl, smartTruncate } from "@/lib/stringUtils";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { CrawlResultSansBody } from "@/types";
import { ArrowTopRightOnSquareIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { AuthoredMedia } from "@prisma/client";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";

interface Props {
  // For testability
  media: Partial<AuthoredMedia>;
  idx: number;
  crawlResult: CrawlResultSansBody | undefined;
  initialEditMode?: boolean;
}

export default function AuthoredMediaRow({ media, idx, crawlResult, initialEditMode }: Props) {
  const canEdit = useStore(entityStore.canEdit);
  const entity = useStore(entityStore.entity);

  const authoredMedia = useStore(entityStore.authoredMedia);
  const [editMode, setEditMode] = useState<boolean>(initialEditMode || false);

  if (authoredMedia.length === 0 && !editMode) {
    return null;
  }

  return (
    <div className="group flex flex-col flex-1 gap-2 static">
      <MediaRow
        key={idx}
        media={media}
        idx={idx}
        crawlResult={crawlResult}
        canEdit={canEdit}
        editMode={editMode}
        setEditMode={setEditMode}
        entityId={entity.id}
      />
    </div>
  );
}

function MediaRow({
  media,
  idx,
  crawlResult,
  canEdit,
  editMode,
  setEditMode,
  entityId,
}: {
  media: Partial<AuthoredMedia>;
  idx: number;
  crawlResult: CrawlResultSansBody | undefined;
  canEdit: boolean;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  entityId: string;
}) {
  return (
    <div key={idx}>
      <div className="flex gap-2 sm:gap-4 -mx-2 p-2 rounded-md">
        <a
          href={media.url}
          title={media.title || media.url}
          target="_blank"
          className="flex flex-1 space-x-1 sm:space-x-3"
          rel="noreferrer"
        >
          <img
            src={favicon({
              url: media.url || "",
              favicon: media.url ? crawlResult?.favicon : undefined,
            })}
            className="w-8 h-8 mt-1 rounded border border-gray-100"
            alt="favicon"
            onError={(e) =>
              ((e.target as HTMLImageElement).src =
                favicon({
                  url: media.url || "",
                  favicon: null,
                }) ?? "")
            }
          />
          <div className="flex-1">
            <div className="font-semibold">{smartTruncate(media.title || "", 80)}</div>
            <div className="flex items-center justify-start mt-1">
              {media.writtenOn && (
                <div className="text-gray-400 mr-2">{moment(media.writtenOn).format("ll")}</div>
              )}
              <span className="text-gray-400">{prettyUrl(media.url || "", true)}</span>
              <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-gray-400" />
            </div>
          </div>
          <div className="flex items-center justify-end">
            {canEdit && (
              <>
                {!editMode ?
                  <PencilSquareIcon
                    className="cursor-pointer hover:text-brand-600 mb-4 h-5 w-5 text-gray-500 group-hover:block hidden"
                    onClick={(event) => {
                      event.preventDefault(); // Prevents the default anchor behavior
                      event.stopPropagation(); // Stops the event from bubbling up
                      setEditMode(true);
                    }}
                  />
                : <Button
                    className="cursor-pointer px-2 mb-4 text-gray-500"
                    variant={ButtonVariant.SmallSecondary}
                    onClick={(event) => {
                      event.preventDefault(); // Prevents the default anchor behavior
                      event.stopPropagation(); // Stops the event from bubbling up
                      setEditMode(false);
                    }}
                  >
                    Finish editing
                  </Button>
                }
              </>
            )}
          </div>
        </a>
      </div>
      {editMode && (
        <div className="flex gap-2 pl-11">
          <Button
            variant={ButtonVariant.SmallSecondary}
            onClick={() => {
              uiStore.showConfirmModal.set({
                type: "danger",
                title: media.title || media.url || "this media",
                subtitle: "Delete this media? The action is not reversible.",
                onClick: async () => {
                  if (media.url) {
                    await entityStore.deleteAuthoredMedia(entityId, media.url);
                    setEditMode(false);
                  } else {
                    toast.error("Error: Media URL is undefined.");
                  }
                },
              });
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
}
