/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useEffect, useState } from "react";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/layout/ProfileBubble";
import Button, { ButtonLink, ButtonVariant } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import Loader from "@/components/ui/Loader";
import { useDevTools } from "@/hooks/useUIStore";
import { classNames } from "@/lib/utils";
import { entityStore } from "@/stores/entityStore";
import { PipelineProgress, PipelineRunStatus } from "@/types";
import { PauseIcon, PlayCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

export default withErrorBoundary(function ShowPipelineStatus({ status }: { status: string }) {
  const entity = useStore(entityStore.entity);
  const progress = useStore(entityStore.progress);
  const updateQueue = useStore(entityStore.updateEntityQueue);
  const isDev = useDevTools();

  // if completed, don't show progress
  if (updateQueue[entity.id]?.resolved) return null;

  if (
    status == PipelineRunStatus.COMPLETED ||
    status == PipelineRunStatus.ABORTED ||
    entity.generatedAt
  ) {
    // only show progress for completed entities if in dev mode
    if (!isDev || !progress) return null;
  }

  // don't show progress for other statuses outside of dev mode
  if (
    !isDev &&
    status != PipelineRunStatus.COMPLETED &&
    status != PipelineRunStatus.IN_PROGRESS &&
    status != PipelineRunStatus.ERROR
  )
    return null;

  return (
    <ProfileBubble className="bg-blue-50 flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <PipelineLabel status={status} progress={progress} />
      </div>
      {progress && (
        <div className="flex items-center gap-x-1">
          {progress.step ?
            new Array(progress.step)
              .fill(0)
              .map((_, i) => <div key={i} className="w-full h-2.5 flex bg-blue-600" />)
          : null}
          <CurrentProgress
            step={progress.step}
            className="w-full h-2.5 flex bg-blue-200"
            filledClass="bg-blue-600"
            estimatedSecs={progress.estimatedSecs}
          />
          {new Array(Math.max(0, progress.stepsLeft - 1)).fill(0).map((_, i) => (
            <div key={i} className="w-full h-2.5 flex bg-gray-300" />
          ))}
          <div>
            <div className="w-10 text-end">
              <span className="text-sm text-gray-800">
                {Math.round((100 * progress.step) / (progress.step + progress.stepsLeft))}%
              </span>
            </div>
          </div>
        </div>
      )}
    </ProfileBubble>
  );
});

function PipelineLabel({ status, progress }: { status: string; progress?: PipelineProgress }) {
  if (progress) status = PipelineRunStatus.IN_PROGRESS;
  switch (status) {
    case PipelineRunStatus.IN_PROGRESS:
      return (
        <>
          <Loader />
          {progress?.label || "Please be patient as we fetch data from across the web..."}
        </>
      );
    case PipelineRunStatus.ERROR:
      return (
        <div className="flex flex-col items-center w-full p-4 gap-2">
          <div className="font-semibold text-xl">
            We&apos;ve run into an issue creating this profile
          </div>
          <div className="text-gray-600 mb-4">Reach out to our support team if this continues</div>
          <ButtonLink
            href="mailto:support@distill.fyi"
            className="font-medium"
            variant={ButtonVariant.Secondary}
          >
            Contact support
          </ButtonLink>
        </div>
      );
    case PipelineRunStatus.PAUSED:
      return (
        <>
          <PauseIcon className="w-4 h-4 text-yellow-500" />
          Pipeline is paused.
        </>
      );
    case PipelineRunStatus.NOT_STARTED:
      return (
        <>
          <PlayCircleIcon className="w-4 h-4 text-green-500" />
          Click to start loading data:
          <Button onClick={() => entityStore.triggerPipeline()}>Start Pipeline</Button>
        </>
      );
    default:
      return (
        <>
          <QuestionMarkCircleIcon className="w-6 h-6 text-blue-500" />
          Unusual pipeline status: {status}
        </>
      );
  }
}

function CurrentProgress({
  step,
  className,
  filledClass,
  estimatedSecs,
}: {
  step: number;
  className: string;
  filledClass: string;
  estimatedSecs: number;
}) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(0);
    const steps = estimatedSecs * 2;
    const timeout = setInterval(() => {
      setWidth((w) => {
        const progressUnit = (100 / steps) * ((200 - w * 1.5) / 100); // ease in
        const next = w + progressUnit;
        if (next >= 100) {
          clearInterval(timeout);
          return 100;
        }
        return next;
      });
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [step, estimatedSecs]);

  return (
    <div className={classNames(className, "relative")}>
      <div
        className={classNames(filledClass, "absolute top-0 left-0 h-full transition-all ")}
        style={{ width: `${width}%`, transitionDuration: width == 0 ? "0s" : "500ms" }}
      />
    </div>
  );
}
