import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { CompanyFact } from "@/models/facts/facts.types";
import {
  PersonCompanyRelationship,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { entityStore } from "@/stores/entityStore";
import { Entity, ProfilePageSection } from "@/types";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { JsonObject } from "@prisma/client/runtime/library";
import { groupBy } from "lodash";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import EntityCard, {
  EntityForCard,
  makeEntityCardFromMutualConnection,
} from "@/components/ui/EntityCard";
import Button, { ButtonVariant } from "@/components/ui/Button";
import TabBar from "@/components/ui/TabBar";
import { useState } from "react";

const MAX_PEOPLE = 8;

const relationshipToEntity = (
  relationship: RelationshipWithEntity,
  resolveCache: Record<string, Entity>,
) => {
  return {
    ...resolveCache[relationship.fromId],
    title: (relationship.data as JsonObject)?.title ?? resolveCache[relationship.fromId].title,
  } as EntityForCard;
};

const CURRENT_TAB = "current";
const MUTUALS_TAB = "mutuals";
const FORMER_TAB = "former";

export default withErrorBoundary(function CompanyPeople() {
  const entity = useStore(entityStore.entity);
  const relationships = useStore(entityStore.relationships);
  const linkedEntities = useStore(entityStore.linkedEntities);
  const facts = useStore(entityStore.facts);
  const mutualConnections = useStore(entityStore.mutualConnections);
  const [selectedTab, setSelectedTab] = useState(0);
  // How many times "Load more" has been clicked
  const [loadMoreClicked, setLoadMoreClicked] = useState(0);

  if (!relationships.length && !mutualConnections.length) return null;

  const currentlyWorkingRelationships = relationships.filter(
    (relationship) =>
      (!relationship.endedDate || relationship.endedDate === "Present") &&
      relationship.type === PersonCompanyRelationship.WorkedAt &&
      relationship.toId === entity.id &&
      !!relationship.fromId,
  );
  const currentPeopleIds = new Set(currentlyWorkingRelationships.map((r) => r.fromId));
  const formerWorkingRelationships = relationships.filter(
    (relationship) =>
      !!relationship.endedDate &&
      relationship.endedDate !== "Present" &&
      relationship.type === PersonCompanyRelationship.WorkedAt &&
      relationship.toId === entity.id &&
      !!relationship.fromId &&
      !currentPeopleIds.has(relationship.fromId),
  );
  const formerPeopleIds = new Set(formerWorkingRelationships.map((r) => r.fromId));
  const importantPeople: EntityForCard[] = currentlyWorkingRelationships
    .filter((relationship) => (relationship.data as JsonObject)?.isImportant)
    .map((relationship) => relationshipToEntity(relationship, linkedEntities));
  const unimportantPeople: EntityForCard[] = currentlyWorkingRelationships
    .filter((relationship) => !(relationship.data as JsonObject)?.isImportant)
    .map((relationship) => relationshipToEntity(relationship, linkedEntities));
  const connectedPeople: EntityForCard[] = Object.values(mutualConnections)
    .filter(
      (connection) =>
        (connection?.data?.isCurrentEmployee ||
          connection?.data?.isCurrentEmployee === undefined) &&
        !(connection?.entity?.id && formerPeopleIds.has(connection.entity.id)),
    )
    .map((connection) => {
      return makeEntityCardFromMutualConnection(connection);
    });
  const formerConnectedPeople: EntityForCard[] = Object.values(mutualConnections)
    .filter(
      (connection) =>
        connection?.data?.isCurrentEmployee === false &&
        !(connection?.entity?.id && currentPeopleIds.has(connection.entity.id)),
    )
    .map((connection) => {
      return makeEntityCardFromMutualConnection(connection);
    });
  const formerPeople = formerWorkingRelationships.map((relationship) =>
    relationshipToEntity(relationship, linkedEntities),
  );

  const currentTabName =
    connectedPeople.length > 0 ? `Current (${connectedPeople.length} Mutuals)` : "Current";
  const formerTabName =
    formerConnectedPeople.length > 0 ?
      `Former (${formerConnectedPeople.length} Mutuals)`
    : "Former";
  const tabs = [
    ...[
      {
        id: CURRENT_TAB,
        name: currentTabName,
        tooltip:
          "People who are associated with this company" +
          (connectedPeople.length > 0 ?
            `, including ${connectedPeople.length} of your mutuals`
          : ""),
      },
    ],
    ...(formerPeople.length > 0 ?
      [
        {
          id: FORMER_TAB,
          name: formerTabName,
          tooltip:
            "People who were associated with this company" +
            (formerConnectedPeople.length > 0 ?
              `, including ${formerConnectedPeople.length} of your mutuals`
            : ""),
        },
      ]
    : []),
  ];

  let peopleToShow: EntityForCard[] = [];
  if (tabs[selectedTab].id === CURRENT_TAB) {
    peopleToShow = importantPeople.concat(connectedPeople).concat(unimportantPeople);
  } else if (tabs[selectedTab].id === FORMER_TAB) {
    peopleToShow = formerConnectedPeople.concat(formerPeople);
  }

  const seenIds = new Set<string>();
  const seenNames = new Set<string>();
  const uniquePeopleEntities = [];
  for (const entity of peopleToShow) {
    if (entity.id) {
      if (!seenIds.has(entity.id)) {
        seenIds.add(entity.id);
        uniquePeopleEntities.push(entity);
      }
    } else if (entity.name) {
      // This might be a false positive, but if we have two people with
      // the same name and no id, it will be very rare for them to be two
      // different people.
      if (!seenNames.has(entity.name)) {
        seenNames.add(entity.name);
        uniquePeopleEntities.push(entity);
      }
    }
  }
  const peopleEntities = uniquePeopleEntities.slice(0, MAX_PEOPLE * (loadMoreClicked + 1));

  const historicalHeadcount = facts[CompanyFact.HistoricalHeadcount]?.value;

  const groupedByYear = groupBy(historicalHeadcount, (item) => new Date(item.date).getFullYear());
  const aggregatedHeadcount = Object.entries(groupedByYear)
    .map(([year, data]) => ({
      date: year,
      headcount: Math.max(...data.map((d) => d.headcount)),
    }))
    .sort((a, b) => Number(a.date) - Number(b.date));

  if (peopleToShow.length === 0 && formerPeople.length === 0 && aggregatedHeadcount.length === 0) {
    return null;
  }
  const peopleTitle = `People ${aggregatedHeadcount.length > 0 ? `(${aggregatedHeadcount[aggregatedHeadcount.length - 1].headcount})` : ""}`;

  const isThereMore = uniquePeopleEntities.length > peopleEntities.length;

  return (
    <ProfileBubble title={peopleTitle} section={ProfilePageSection.People}>
      <div className="mt-6 mb-2">
        {aggregatedHeadcount.length > 0 && (
          <ResponsiveContainer className="mt-4" width="100%" height={250}>
            <BarChart data={aggregatedHeadcount} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
              <XAxis
                dataKey="date"
                tickFormatter={(tick) => new Date(tick as string).getFullYear().toString()}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value: number) => {
                  if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(value % 1000000 !== 0 ? 2 : 0)}M`;
                  } else if (value >= 1000) {
                    return `${(value / 1000).toFixed(value % 1000 !== 0 ? 2 : 0)}k`;
                  } else {
                    return value.toString();
                  }
                }}
              />
              <Tooltip formatter={(value) => value.toLocaleString()} />
              <CartesianGrid vertical={false} />
              <Bar dataKey="headcount" fill="#8884d8" radius={[5, 5, 0, 0]} barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>

      <TabBar
        tabs={tabs}
        selected={selectedTab}
        className="mb-2"
        onSelectTab={(tab) => {
          setSelectedTab(tab);
          setLoadMoreClicked(0);
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {peopleEntities.map((person) => {
          return <EntityCard entity={person} key={person.id || person.name} />;
        })}
      </div>
      {isThereMore && (
        <Button
          className="mt-2 w-full text-brand-800 font-semibold"
          variant={ButtonVariant.Clear}
          onClick={() => setLoadMoreClicked(loadMoreClicked + 1)}
        >
          View more people
          <ArrowRightIcon className="w-4 h-4 ml-2 inline" />
        </Button>
      )}
    </ProfileBubble>
  );
});
