import ProfileBubble from "@/components/sections/ProfileBubble";
import { ClearButton } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoText from "@/components/ui/ExpandoText";
import { EntityFact } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { EntityType, ProfilePageSection } from "@/types";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

export default withErrorBoundary(function AboutSection() {
  const snapshot = useStore(entityStore.snapshot);
  const canEdit = useStore(entityStore.canEdit);

  const aboutSection = snapshot?.facts?.[EntityFact.About]?.value;
  if (!aboutSection && !canEdit) return null;

  const title = snapshot.entityType == EntityType.Person ? "About me" : "About";

  const edit = () => {
    entityStore.showFactEditModal.set({
      type: EntityFact.About,
      currentValue: aboutSection || "",
    });
  };

  return (
    <ProfileBubble
      className="flex flex-col gap-2 relative"
      title={title}
      section={ProfilePageSection.About}
    >
      {canEdit && (
        <ClearButton
          className="absolute top-2 right-2 text-gray-300 hover:text-gray-500"
          onClick={edit}
        >
          <PencilIcon className="w-4 h-4" />
        </ClearButton>
      )}
      <div className="whitespace-pre-wrap">
        {aboutSection ?
          <ExpandoText text={aboutSection} limit={500} />
        : <div className="text-gray-400">No description</div>}
      </div>
    </ProfileBubble>
  );
});
