import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { favicon as favFunc, prettyUrl, smartTruncate } from "@/lib/utils";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { ProfilePageSection } from "@/types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { Fragment } from "react";
import { twJoin } from "tailwind-merge";

export default withErrorBoundary(function SourcesV3() {
  const snapshot = useStore(entityStore.snapshot);
  const currentHash = useStore(uiStore.currentHash);

  if (!snapshot) return null;

  return (
    <ProfileBubble title="Sources" section={ProfilePageSection.Sources}>
      <div role="list" className="flex flex-col">
        {snapshot?.references
          ?.filter((e) => !snapshot?.notableLinkIds?.includes(e.id))
          // .toSorted((a, b) => a?.title?.localeCompare(b?.title))
          .map(({ id, url, notes, summary, title, datePublished, description, favicon }, idx) => {
            return (
              <Fragment key={id}>
                <div
                  className={twJoin(
                    "flex gap-2 sm:gap-4 -mx-2 p-2 rounded-md",
                    currentHash === `source${id}` && "bg-yellow-50",
                  )}
                >
                  <a id={`source${id}`} />
                  <div className="w-2 text-gray-400">{idx + 1}</div>
                  <a
                    href={url}
                    title={url}
                    target="_blank"
                    className="flex flex-1 space-x-1 sm:space-x-3"
                    rel="noreferrer"
                  >
                    <img
                      src={favFunc({
                        url: url,
                        favicon: favicon,
                      })}
                      className="w-8 h-8 -mt-1 rounded border border-gray-100"
                      alt="favicon"
                      onError={(e) =>
                        ((e.target as HTMLImageElement).src =
                          favFunc({
                            url: url,
                            favicon: null,
                          }) ?? "")
                      }
                    />
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span className="font-semibold">
                          {smartTruncate(
                            (title !== "undefined" ? title : undefined) ||
                              prettyUrl(url, true) ||
                              "",
                            100,
                          )}
                        </span>
                        <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-gray-400" />
                        <span className="ml-2 text-gray-400">{prettyUrl(url, true)}</span>
                      </div>
                    </div>
                  </a>
                  {datePublished && (
                    <div className="hidden sm:block text-gray-400">
                      {moment(datePublished).format("ll")}
                    </div>
                  )}
                </div>

                <div className="mb-4 sm:ml-10">
                  {description && (
                    <div className="text-xs">
                      <div className="">{description}</div>
                    </div>
                  )}
                  {summary && (
                    <div className="text-xs">
                      <div className="text-gray-400 whitespace-pre-wrap">
                        <span className="font-semibold">Summary: </span>
                        {summary}
                      </div>
                    </div>
                  )}
                  {notes && (
                    <div className="text-xs">
                      <div className="text-gray-400 whitespace-pre-wrap">
                        <span className="font-semibold">Why it&apos;s relevant: </span>
                        {notes}
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            );
          })}
        {!!snapshot?.allReferencesLength && <hr />}
      </div>
      {!snapshot?.allReferencesLength && (
        <div className="text-gray-400 text-center">No sources yet</div>
      )}
      <Link
        href={`${snapshot?.slug}/sources`}
        className={twJoin("text-blue-600 hover:text-blue-800 p-4 -mb-4 block w-[100%] text-center")}
      >
        {snapshot?.allReferencesLength ?
          `Manage All Sources (${snapshot?.allReferencesLength})`
        : "Add Sources"}
      </Link>
    </ProfileBubble>
  );
});
